<template>
  <!-- 裂变式盈利案例图 -->
  <div class="sales_carousel_container w">
    <div class="carousel_item">
      <div class="carousel_item_left">
        <img src="@/assets/images/sales/sales_oneday.png" alt="" />
      </div>
      <div class="carousel_item_right">
        <div class="img">
          <img src="@/assets/images/sales/sales_3.png" alt="" />
        </div>
        <div class="achievement">
          <QbTag content="销讲赋能视界" width="144" height="44"></QbTag>
          <div class="achievement_content">
            销讲大师已经赋能5000多位企业家完成自身的蜕变，成功成为舞台上最闪亮的星
          </div>
        </div>
        <div class="achievement">
          <QbTag content="销讲赋能视界" width="144" height="44"></QbTag>
          <div class="achievement_content">
            销讲大师已经走过6个国家30多个城市，带企业家用商业的眼光看待整个世界
          </div>
        </div>
        <div class="img">
          <img src="@/assets/images/sales/sales_4.png" alt="" />
        </div>

        <div class="finish">
          <div class="finish_logo">
            <img src="@/assets/images/sales/logo2.png" alt="" />
          </div>
          <div class="finish_content">
            成交的力度取决于演讲的高度，不需要天赋，只传授方法，掌握核心技能，让你成为销讲大师。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QbTag from '@/components/qb-tag.vue'
export default {
  components: {
    QbTag
  }
}
</script>

<style lang="less" scoped>
.sales_carousel_container {
  width: 1558px;
  margin-top: 32px;
  margin-bottom: 193px;
  .carousel_item {
    display: flex;
    width: 1558px;
  }
  .carousel_item_left {
    width: 779px;
  }
  .carousel_item_right {
    display: flex;
    flex-wrap: wrap;
    width: 779px;
    .img {
      width: 389px;
      height: 349px;
    }
    .achievement {
      width: 390px;
      height: 349px;
      padding: 95px 0 0 46px;
      .achievement_content {
        width: 313px;
        height: 96px;
        font-size: 21px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 32px;
        margin-top: 20px;
      }
    }
    .finish {
      position: relative;
      background-color: #010343;
      width: 779px;
      height: 351px;
      .finish_logo {
        position: absolute;
        width: 136px;
        height: 140px;
        top: 43px;
        left: 43px;
      }
      .finish_content {
        position: absolute;
        left: 349px;
        top: 85px;
        width: 369px;
        height: 224px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 56px;
      }
    }
  }
}
</style>
