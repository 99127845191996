<template>
  <div class="sales_container">
    <!-- 顶部导航的占位 -->
    <QbTopnavPlaceholder></QbTopnavPlaceholder>

    <!--销讲大师计划标题 -->
    <QbTitle :title="title" :complement="complement"></QbTitle>

    <!-- 销讲大师计划封面图 -->
    <QbCover :src="src"></QbCover>

    <!-- 销讲大师计划课程安排 -->
    <QbSchedule :schedule="schedule"></QbSchedule>

    <!-- 销讲大师计划内容 -->
    <SalesContent></SalesContent>

    <!-- 销讲大师计划内容 -->
    <SalesCarousel></SalesCarousel>

    <!-- 报名组件 -->
    <QbApplication></QbApplication>
  </div>
</template>

<script>
import SalesContent from './components/sales-content.vue'
import SalesCarousel from './components/sales-carousel.vue'
export default {
  components: {
    SalesContent,
    SalesCarousel
  },
  data() {
    return {
      title: '《销讲大师计划》',
      complement: 'Quanbang Group sales speak master plan',
      src: require('@/assets/images/sales/sales_cover.png'),
      schedule: require('@/assets/images/sales/sales_schedule.png')
    }
  }
}
</script>

<style></style>
