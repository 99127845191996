<template>
  <div class="sales_content_container">
    <div class="sales_content w">
      <div class="sales_content_left">
        <div class="left_title">掌握销讲 赋能招商</div>
        <div class="left_introduction">
          作为一名企业家，无论是在公司内部开会还是对外招商谈判，最不可缺少的能力就是销讲能力，这也是泉邦倾情打造《销讲大师计划》的原因。<br />
          挖掘你的潜能，用销讲的魅力征服世界，让你不仅能“讲”还能“销”，让你拥有公众演说能力和超强销售能力，将原本的招商能力放大数十倍。
        </div>
        <div class="left_content">
          <div class="mb">
            <img src="@/assets/images/sales/internal.png" alt="" />
          </div>
          <div class="mb">
            <img src="@/assets/images/sales/sales_1.png" alt="" />
          </div>
          <div>
            <img src="@/assets/images/sales/sales_2.png" alt="" />
          </div>
          <div>
            <img src="@/assets/images/sales/internal2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="sales_content_right">
        <div class="question">如何用有逻辑的演讲 建立起你的话语权？</div>
        <div class="answer">
          通过逻辑清晰的正确演讲，表达我们内心的思想，建立起话语权，拉近我们与听众的距离，从而获得听众的认可。这里的听众，既可以是自己企业的员工，亦可以是拥有潜在资源的客户对象。<br />
          学会演讲可以说是企业经营和品牌推广过程中不能避免的一环。
        </div>
        <div class="ace_lecturer">课程金牌讲师</div>
        <div class="content">
          <div class="lecturer">
            <div class="lecturer_avatar">
              <img src="@/assets/images/sales/mbc.png" alt="" />
            </div>
            <div class="lecturer_name">马煜超</div>
            <div class="lecturer_identity">
              <div>泉邦集团董事长</div>
            </div>
          </div>
          <div class="process">
            <ul>
              <li>
                <div class="process_number">ONE</div>
                <div class="process_title">
                  财富裂变
                </div>
              </li>
              <li>
                <div class="process_number">TWO</div>
                <div class="process_title">
                  人才裂变
                </div>
              </li>
              <li>
                <div class="process_number">THREE</div>
                <div class="process_title">
                  渠道裂变
                </div>
              </li>
              <li>
                <div class="process_number">FOUR</div>
                <div class="process_title">
                  时间裂变
                </div>
              </li>
              <li>
                挖掘你的潜能，用销讲的魅力征服世界，让你不仅能“讲”还能“销”
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.sales_content {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.sales_content_left {
  width: 757px;
  height: 939px;
  padding: 43px 27px 0px 36px;
  margin-right: 47px;
  .left_title {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    line-height: 44px;
    margin-bottom: 21px;
  }
  .left_introduction {
    font-size: 24px;
    font-weight: 300;
    color: #010343;
    line-height: 48px;
  }
  .left_content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 33px;
    justify-content: space-between;
    div {
      width: 343px;
      height: 217px;
      &.mb {
        margin-bottom: 28px;
      }
    }
  }
}
.sales_content_right {
  width: 757px;
  height: 939px;
  padding: 43px 27px 0px 36px;
  .question {
    font-size: 32px;
    font-weight: 500;
    color: #010343;
    line-height: 44px;
    margin-bottom: 21px;
  }
  .answer {
    width: 695px;
    height: 240px;
    font-size: 24px;
    font-weight: 400;
    color: #010343;
    line-height: 48px;
  }
  .ace_lecturer {
    font-size: 24px;
    font-weight: 500;
    color: #010343;
  }
  .content {
    display: flex;
  }

  .lecturer {
    display: flex;
    flex-direction: column;
    margin-top: 23px;
    margin-right: 13px;
    .lecturer_avatar {
      width: 224px;
      height: 230px;
    }
    .lecturer_name {
      width: 224px;
      height: 45px;
      line-height: 45px;
      background: #1651dd;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      margin-top: 15px;
    }
    .lecturer_identity {
      width: 224px;
      height: 205px;
      background: #f7f9fd;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      font-size: 19px;
      font-weight: 400;
      color: #010343;
      line-height: 27px;
    }
  }

  .process {
    li {
      display: flex;
      margin-top: 21px;
      &:nth-child(5) {
        padding: 31px 29px 40px 15px;
        width: 457px;
        height: 145px;
        background: #1651dd;
        font-size: 24px;
        font-weight: 600;
        color: #ffffff;
        line-height: 37px;
        text-align: center;
      }
    }
    .process_number {
      width: 67px;
      height: 67px;
      background: #1651dd;
      font-size: 19px;
      font-weight: 500;
      color: #ffffff;
      line-height: 67px;
      text-align: center;
    }
    .process_title {
      width: 381px;
      height: 67px;
      line-height: 67px;
      text-align: center;
      background: #f7f9fd;
      font-size: 24px;
      font-weight: 500;
      color: #010343;
    }
  }
}
</style>
